import 'select2';
// Select2 init
export default function select2() {
    // Single
    const $select2 = $('.js-select2');
    const $select2w100 = $('.js-select2-w100');

    $select2.each(function () {
        $(this).select2({
            minimumResultsForSearch: -1,
            dropdownAutoWidth: true,
        });
    });

    $select2w100.each(function () {
        $(this).select2({
            minimumResultsForSearch: -1,
            dropdownAutoWidth: true,
            width: '100%',
        });
    });
}
