// MINI CART
getMiniCart();

const { default: select2 } = require('../scripts/select2'); //imports

(function ($) {
    $(document.body).on('applied_coupon', function () {
        $('html, body').animate(
            {
                scrollTop: $('body').offset().top,
            },
            1000
        );
    });

    $(document).on('change', '.js-cart-item-qty', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const $productQtySelect = $(this);

        if ($productQtySelect.is('select')) {
            var $productQtySelectValue = $productQtySelect
                .find('option:selected')
                .val();
        } else {
            var $productQtySelectValue = $productQtySelect.val();
            $('.js-qty-wrapper').addClass('input-is-active');
        }

        $.ajax({
            type: 'post',
            url: base_url + '/wp-json/wc/v3/shop/cart-change-item-qty',
            data: {
                action: 'mda_mini_cart_change_item_qty',
                cart_item_key: $productQtySelect.attr('data-cart-item-key'),
                qty: $productQtySelectValue,
            },
            beforeSend: function () {
                blockMiniCart();
            },
            success: function (response) {
                updateMiniCart(response);
                unblockMiniCart();
                if ($(document).find("[name='update_cart']").length) {
                    _updateCart();
                }
                $('.js-qty-wrapper').addClass('input-is-active');
            },
        });
    });

    $(document.body).on('updated_cart_totals', function () {
        select2();
        getMiniCart();
    });

    $(document).on('click', '.js-cart-ajax-remove', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $.ajax({
            type: 'delete',
            url: base_url + '/wp-json/wc/v3/shop/cart-remove-item',
            data: {
                action: 'mda_remove_from_cart',
                cart_item_key: $(this).attr('data-cart-item-key'),
            },
            beforeSend: function () {
                blockMiniCart();
            },
            success: function (response) {
                updateMiniCart(response);
                unblockMiniCart();
            },
        });
    });

    // Toggle cart on trigger click
    $(document).on('click', '.js-mini-cart-trigger', function (e) {
        // Return on desktop scaled dimensions
        if (window.innerWidth < 1180 || window.innerHeight < 600) {
            hideMiniCart();
            console.log('Window is too small to place the mini cart');
            return;
        }

        e.preventDefault();
        e.stopImmediatePropagation();

        if ($('#js-mini-cart').length > 0) {
            toggleMiniCart();
        }
    });

    // Update cart on 'add to cart' and  'mda_update_minicart' action
    $(document).on('added_to_cart mda_update_minicart', function () {
        getMiniCart();
    });

    // Hide on scroll
    $(document).on('scroll', function (e) {
        if ($(window).innerWidth() > 576) {
            hideMiniCart();
        }
    });

    // Hide on resize
    $(window).on('resize', function (e) {
        hideMiniCart();
    });

    // Hide on click out of cart
    if ($(window).innerWidth() > 576) {
        $(document).on('click', function (e) {
            let $miniCartDiv = $('#js-mini-cart');
            let $miniCartTrigger = $('.js-mini-cart-trigger');

            if (
                !$miniCartTrigger.is(e.target) &&
                !$miniCartDiv.is(e.target) &&
                $miniCartDiv.has(e.target).length === 0
            ) {
                hideMiniCart();
            }
        });
    }

    // Apply coupon on enter key
    $(document).on('keyup', '.js-coupon-input', function (e) {
        const couponApplyBtn = $('.js-coupon-apply');
        if (couponApplyBtn && e.key === 'Enter') {
            e.preventDefault();
            couponApplyBtn.click();
        }
    });
})(jQuery);

function getMiniCart() {
    $.ajax({
        type: 'post',
        url: base_url + '/wp-json/wc/v3/shop/cart-get-items',
        data: {
            action: 'mda_get_mini_cart',
        },
        beforeSend: function () {
            blockMiniCart();
        },
        success: function (response) {
            updateMiniCart(response);
            unblockMiniCart();
        },
    });
}

function toggleMiniCart() {
    let isMiniCartOpen = $('#js-mini-cart').hasClass('-show');
    if (isMiniCartOpen) {
        hideMiniCart();
    } else {
        showMiniCart();
    }
}

function hideMiniCart() {
    let $miniCartDiv = $('#js-mini-cart');
    $miniCartDiv.removeClass('-show');
}

function showMiniCart() {
    let $miniCartDiv = $('#js-mini-cart');
    placeMiniCart();
    select2();
    $miniCartDiv.addClass('-show');
}

function updateMiniCart(response) {
    let $miniCartDiv = $('#js-mini-cart');
    setTimeout(() => {
        select2();
    }, 1);
    updateMiniCartLabel(response.qty);
    $miniCartDiv.html(response.cart);
}

function blockMiniCart() {
    let $miniCartDiv = $('#js-mini-cart');
    $miniCartDiv.addClass('-blocked -load');

    const $label = $('.js-mini-cart-count');
    $label.addClass('is-load');
}

function unblockMiniCart() {
    let $miniCartDiv = $('#js-mini-cart');
    $miniCartDiv.removeClass('-blocked -load');

    const $label = $('.js-mini-cart-count');
    $label.removeClass('is-load');
}

function updateMiniCartLabel(itemCount) {
    let labelItemCount = itemCount;
    if (typeof itemCount !== 'number') {
        labelItemCount = 0;
        console.warn('Empty itemCount argument or does not exists.');
        return;
    }
    const $count = $('.js-mini-cart-count');
    $count.html(`(${labelItemCount})`);
}

function placeMiniCart(options) {
    const $miniCart = $('#js-mini-cart');
    const $miniCartAnchor = $('.js-mini-cart-anchor');
    const $miniCartTrigger = $('.js-mini-cart-trigger');

    // Get param options or default vals
    const { position, fixed } = {
        position: 'bottom center',
        fixed: true,
        ...options,
    };

    // Reset and return if mobile
    if ($(window).innerWidth() < 768) {
        $miniCart.css('top', '');
        $miniCart.css('left', '');

        return;
    }
    const $anchor =
        $miniCartAnchor.length > 0
            ? $miniCartAnchor.first()
            : $miniCartTrigger.first();

    if ($anchor.length === 0) {
        console.error('No cart anchor selected');
        return;
    }

    // Get anchor pos
    let anchorPos = $anchor.offset();

    const anchorSize = {
        width: $anchor.innerWidth(),
        height: $anchor.innerHeight(),
    };

    // Define variable for target position
    let targetPos = anchorPos;

    switch (position) {
        case 'bottom left':
            targetPos.top += anchorSize.height + 5;
            break;

        case 'bottom center':
            targetPos.top += anchorSize.height + 5;
            targetPos.left += anchorSize.width;
            break;
    }
}

function _updateCart() {
    let timeout;

    if (timeout !== undefined) {
        clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
        $("[name='update_cart']").trigger('click');
    }, 1000);
}

export { showMiniCart, hideMiniCart, getMiniCart };
