// AJAX ADD TO CART
import { renderFragments } from '../helpers/render-fragments';
import { showHeaderSticky } from '../scripts/header-sticky';
import { showMiniCart } from './cart';
window.dataLayer = window.dataLayer || [];

(function ($) {
    // Product listing add to cart button
    $(document).on('click', '.js-single-add-to-cart', function (e) {
        e.preventDefault();

        const button = $(this);

        addToCart({
            button: button,
            productId: button.attr('data-product_id'),
            qty: 1,
        });
    });

    // Variation
    $(document).on('click', '.js-variation-add-to-cart', function (e) {
        e.preventDefault();

        const button = $(this);

        if (!button.attr('data-variation_id')) return;

        addToCart({
            button: button,
            productId: button.attr('data-product_id'),
            qty: 1,
            variationId: button.attr('data-variation_id'),
        });
    });
})(jQuery);

function addToCart({
    action = 'mda_add_to_cart',
    button,
    productId,
    qty,
    variationId = false,
    additionalData = {},
}) {
    $.ajax({
        url: base_url + '/wp-json/wc/v3/shop/cart-add-item',
        data: {
            action: action,
            product_id: productId,
            qty: qty,
            variation_id: variationId,
            additional_data: additionalData,
        },
        type: 'post',
        beforeSend: function () {
            handleAjaxBeforeSend(button);
        },
        success: response => {
            handleAjaxSuccess(response, button);
            if (response.ga) {
                window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: response.ga,
                });
            }
        },
    });
}

function handleAjaxBeforeSend(button) {
    button.parents('.js-product-box').block({
        message: null,
        overlayCSS: {
            background: '#fff',
            opacity: 0.6,
        },
    });
    button.addClass('is-loading').addClass('-disabled');
    showHeaderSticky();
}

function handleAjaxSuccess(response, button) {
    button.removeClass('is-loading').removeClass('-disabled');
    button.parents('.js-product-box').unblock();

    if (response.status == 'ok') {
        if (button.hasClass('-add-to-cart-small')) {
            button.addClass('is-done');
        } else {
            button.text('Produkt dodany').addClass('is-done');
        }

        $(document).trigger('added_to_cart'); // UPDATE MINI CART

        if (button.hasClass('-add-to-cart-small')) {
            window.setTimeout(function () {
                button.removeClass('is-done');
            }, 2000);
        } else {
            window.setTimeout(function () {
                button.text('Do koszyka').removeClass('is-done');
            }, 2000);
        }

        // WP Fragments render
        renderFragments(response);

        // Open mini cart
        if (window.innerWidth > 1024) {
            showMiniCart();
        }
    }

    if (response.status == 'error' && response.message) {
        var alert_data = {
            type: 'alert:show',
            title: response.message,
            text: response.text,
            error: true,
        };
        $('body').trigger(alert_data);
    }
}

export { addToCart, handleAjaxSuccess, handleAjaxBeforeSend };
