/**
 * Page Header Sticky block script
 */
export default function headerSticky() {
    const header = document.getElementById('js-header-main');
    let lastScrollTop = 0;

    document.addEventListener('scroll', event => {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        //scroll direction
        if (window.scrollY > 180 && st > lastScrollTop) {
            header.classList.add('is-active');
        } else {
            header.classList.remove('is-active');
        }

        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });
}

function showHeaderSticky() {
    const header = document.getElementById('js-header-main');
    header.classList.add('is-active');
}

export { showHeaderSticky };
