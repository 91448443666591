/**
 * Handle WC Fragments from response
 * @param {object} response
 */
function renderFragments(response) {
    if (response.fragments && Object.keys(response.fragments).length > 0) {
        Object.entries(response.fragments).forEach(([selector, html]) => {
            const element = $(selector);
            element.html(html);
        });
    }
}

export { renderFragments };
